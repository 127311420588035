// pages/ContactUsDetail.tsx
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/auth";
import { baseApiUrl } from "../../../utils/constants";
import IContactUs from "../../../interfaces/contactUs";

const ContactUsDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [contact, setContact] = useState<IContactUs | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContact = async () => {
      setLoading(true);
      const token = getToken();
      try {
        const response = await axios.get(
          `${baseApiUrl}api/services/app/ContactUs/Get`,
          {
            params: { id },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setContact(response.data.result);
      } catch (error) {
        console.error("Error fetching contact", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchContact();
    }
  }, [id]);

  const handleDeleteItem = () => {
    handleDelete(contact?.id);
  };

  const handleDelete = async (id?: string) => {
    if (!id) {
      return;
    }
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (userConfirmed) {
      const token = getToken();
      await axios.delete(`${baseApiUrl}api/services/app/ContactUs/Delete`, {
        params: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      navigate(-1);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!contact) {
    return <p>Contact not found.</p>;
  }

  return (
    <div className="p-0">
      <h1 className="text-2xl mb-4">Message</h1>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Name:
          </label>
          <p className="mt-1 text-gray-900">{contact.name}</p>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email:
          </label>
          <p className="mt-1 text-gray-900">{contact.email}</p>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Message:
          </label>
          <p className="mt-1 text-gray-900 whitespace-pre-line">
            {contact.message}
          </p>
        </div>
      </div>
      <div className="mt-6 flex justify-end space-x-4">
        <button
          onClick={() => navigate(-1)}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
        >
          Back
        </button>
        <button
          onClick={handleDeleteItem}
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default ContactUsDetailPage;
