// pages/dashboard/index.tsx
import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import News from "../news";
import ArticleForm from "../../components/articleForm";
import Research from "../research";
import InboxPage from "../inbox";
import { FaBars } from "react-icons/fa";
import SideNav from "../../components/sideNav";
import ContactUsDetailPage from "../inbox/detail";

const Dashboard: React.FC = () => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Side Navigation */}
      <SideNav isOpen={isSideNavOpen} onClose={() => setIsSideNavOpen(false)} />

      {/* Main content */}
      <div className="flex-1 flex flex-col ml-0">
        {/* Header with Hamburger Menu */}
        <header className="p-4 bg-white shadow-md flex items-center justify-between md:hidden">
          <button
            onClick={() => setIsSideNavOpen(true)}
            className="text-2xl text-gray-700"
          >
            <FaBars />
          </button>
          <h1 className="text-xl font-bold">Dashboard</h1>
          <div></div> {/* Empty div to balance the header */}
        </header>

        {/* Content area */}
        <main className="flex-1 overflow-y-auto p-4">
          <Routes>
            {/* Your routes */}
            <Route path="news" element={<News />} />
            <Route
              path="news/create"
              element={<ArticleForm articleType={1} />}
            />
            <Route
              path="news/edit/:id"
              element={<ArticleForm articleType={1} />}
            />
            <Route path="research" element={<Research />} />
            <Route
              path="research/create"
              element={<ArticleForm articleType={2} />}
            />
            <Route
              path="research/edit/:id"
              element={<ArticleForm articleType={2} />}
            />
            <Route path="inbox" element={<InboxPage />} />
            <Route path="inbox/:id" element={<ContactUsDetailPage />} />
            <Route path="" element={<Navigate to="news" />} />
            <Route path="*" element={<Navigate to="news" />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
