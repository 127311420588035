import { useState, useEffect } from "react";
import axios from "axios";
import IContactUs from "../../interfaces/contactUs";
import { getToken } from "../../utils/auth";
import { baseApiUrl } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

const useInboxList = () => {
  const [messages, setMessages] = useState<IContactUs[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [pageSize] = useState(10); // Number of items per page
  const [totalItems, setTotalItems] = useState(0); // Total number of items

  const fetchArticles = async () => {
    setLoading(true);
    const token = getToken(); // Retrieve the stored token
    try {
      const response = await axios.get(
        `${baseApiUrl}api/services/app/ContactUs/GetAll`,
        {
          params: {
            Keyword: searchKeyword,
            MaxResultCount: pageSize,
            SkipCount: (currentPage - 1) * pageSize,
          },
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        }
      );
      setMessages(response?.data?.result?.items);
      setTotalItems(response?.data?.result?.totalCount);
    } catch (error) {
      console.error("Error fetching articles", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, [searchKeyword, currentPage]);

  const handleView = (id?: string) => {
    if (!id) {
      return;
    }
    navigate(`${id}`);
  };

  const handleDelete = async (id?: string) => {
    if (!id) {
      return;
    }
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (userConfirmed) {
      const token = getToken();
      await axios.delete(`${baseApiUrl}api/services/app/ContactUs/Delete`, {
        params: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      fetchArticles();
    }
  };

  return {
    messages,
    loading,
    searchKeyword,
    setSearchKeyword,
    handleDelete,
    handleView,
    currentPage,
    setCurrentPage,
    pageSize,
    totalItems,
  };
};

export default useInboxList;
