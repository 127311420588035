// components/SideNav.tsx
import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import {
  FaNewspaper,
  FaFlask,
  FaInbox,
  FaSignOutAlt,
  FaTimes,
} from "react-icons/fa";
import logo from "../../assets/images/menulogo.png";

interface SideNavProps {
  isOpen: boolean;
  onClose: () => void;
}

const SideNav: FC<SideNavProps> = ({ isOpen, onClose }) => {
  return (
    <>
      {/* Overlay for mobile */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40 md:hidden"
          onClick={onClose}
        ></div>
      )}

      {/* SideNav */}
      <aside
        className={`fixed inset-y-0 left-0 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 md:static w-64 bg-menuBgColor2 text-white flex flex-col justify-between p-4 transition-transform duration-300 ease-in-out z-50`}
      >
        <div>
          {/* Close button for mobile */}
          <div className="md:hidden flex justify-end">
            <button onClick={onClose} className="text-white text-2xl">
              <FaTimes />
            </button>
          </div>
          <img src={logo} alt="Logo" className="w-45 mx-auto mb-6" />
          <nav className="space-y-4">
            <NavLink
              to="/dashboard/news"
              className={({ isActive }) =>
                `flex items-center w-full py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out ${
                  isActive ? "bg-primaryColor" : "hover:bg-primaryColor"
                }`
              }
            >
              <FaNewspaper className="mr-3" size={20} />
              News
            </NavLink>
            <NavLink
              to="/dashboard/research"
              className={({ isActive }) =>
                `flex items-center w-full py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out ${
                  isActive ? "bg-primaryColor" : "hover:bg-primaryColor"
                }`
              }
            >
              <FaFlask className="mr-3" size={20} />
              Research
            </NavLink>
            <NavLink
              to="/dashboard/inbox"
              className={({ isActive }) =>
                `flex items-center w-full py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out ${
                  isActive ? "bg-primaryColor" : "hover:bg-primaryColor"
                }`
              }
            >
              <FaInbox className="mr-3" size={20} />
              Inbox
            </NavLink>
          </nav>
        </div>

        <button
          onClick={() => {
            localStorage.removeItem("authToken");
            window.location.href = "/login";
          }}
          className="flex items-center w-full py-2 px-4 rounded-lg mt-6 hover:bg-primaryColor transition-colors duration-300 ease-in-out"
        >
          <FaSignOutAlt className="mr-3" size={20} />
          Logout
        </button>
      </aside>
    </>
  );
};

export default SideNav;
