// pages/Login.tsx
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setToken } from "../../utils/auth";
import logo from "../../assets/images/logo.jpeg";
import { baseApiUrl } from "../../utils/constants";

const Login = () => {
  const [userNameOrEmailAddress, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Loader state
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        `${baseApiUrl}api/TokenAuth/Authenticate`,
        {
          userNameOrEmailAddress,
          password,
          rememberClient: true,
        }
      );
      setToken(response.data.result.accessToken);
      navigate("/dashboard");
    } catch (error) {
      setError("Invalid login credentials");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-4 sm:mx-auto animate-fade-in-up">
        <img
          src={logo} // Ensure to use the path to your logo image
          alt="Logo"
          className="w-100 mx-auto animate-slide-in-top"
        />
        <h2 className="text-primaryColor text-xl text-center mb-4 animate-fade-in">
          Admin Portal
        </h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            className="border border-gray-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-primaryColor transition-all duration-300 animate-slide-in-right"
            type="text"
            placeholder="Username or Email"
            value={userNameOrEmailAddress}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className="border border-gray-300 p-2 w-full rounded-lg focus:ring-2 focus:ring-primaryColor transition-all duration-300 animate-slide-in-right"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className={`w-full bg-primaryColor text-white py-2 rounded-lg transition-all ${
              loading ? "opacity-50" : ""
            }`}
            type="submit"
            disabled={loading}
          >
            {loading ? "Logging in..." : "Login"}
          </button>
        </form>
        {loading && (
          <div className="flex justify-center mt-4">
            <div className="loader border-t-primaryColor"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
