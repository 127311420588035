// ArticleForm.tsx
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import IArticle from "../../interfaces/article";
import { getToken } from "../../utils/auth";
import { baseApiUrl } from "../../utils/constants";
import { IArticleFormProps } from "./interface";

const ArticleForm = (props: IArticleFormProps) => {
  const [article, setArticle] = useState<IArticle>({
    category: "",
    image: "",
    title: "",
    description: "",
    link: "",
    linkText: "",
    publishDate: new Date().toISOString(),
    publisher: "",
    type: props.articleType,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>(); // Get the article ID from the URL if editing

  useEffect(() => {
    if (id) {
      // Fetch the existing article data for editing
      fetchArticle();
    }
  }, [id]);

  const fetchArticle = async () => {
    setLoading(true);
    const token = getToken();
    try {
      const response = await axios.get(
        `${baseApiUrl}api/services/app/Article/Get`,
        {
          params: { id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setArticle(response.data.result);
    } catch (error) {
      console.error("Error fetching article", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setArticle({ ...article, [e.target.name]: e.target.value });
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value; // Format: 'YYYY-MM-DD'
    const datetimeString = `${selectedDate}T12:00:00.000Z`; // Time set to noon UTC
    setArticle({
      ...article,
      publishDate: datetimeString,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const token = getToken();
    try {
      if (id) {
        // Update existing article
        await axios.put(
          `${baseApiUrl}api/services/app/Article/Update`,
          article,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        // Create new article
        await axios.post(
          `${baseApiUrl}api/services/app/Article/Create`,
          article,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      navigateBack();
    } catch (error) {
      console.error("Error saving article", error);
    } finally {
      setLoading(false);
    }
  };

  const navigateBack = () => {
    if (props.articleType === 1) {
      navigate("/dashboard/news"); // Redirect back to the News page
    } else {
      navigate("/dashboard/research"); // Redirect back to the Research page
    }
  };

  const formatDateForInput = (dateString: string) => {
    if (!dateString) return "";
    return dateString.split("T")[0];
  };

  const getTitle = () => {
    if (id) {
      return props.articleType === 1
        ? "Edit News Article"
        : "Edit Research Article";
    } else {
      return props.articleType === 1
        ? "Create News Article"
        : "Create Research Article";
    }
  };

  return (
    <div className="p-4 flex-1">
      <h1 className="text-2xl mb-4">{getTitle()}</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              name="title"
              value={article.title}
              onChange={handleChange}
              className="mt-1 block w-full border p-2 rounded-md shadow-sm"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              name="description"
              value={article.description}
              onChange={handleChange}
              className="mt-1 block w-full border p-2 rounded-md shadow-sm"
              required
            ></textarea>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Category
            </label>
            <input
              type="text"
              name="category"
              value={article.category}
              onChange={handleChange}
              className="mt-1 block w-full border p-2 rounded-md shadow-sm"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Image URL
            </label>
            <input
              type="text"
              name="image"
              value={article.image}
              onChange={handleChange}
              className="mt-1 block w-full border p-2 rounded-md shadow-sm"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Link
            </label>
            <input
              type="text"
              name="link"
              value={article.link}
              onChange={handleChange}
              className="mt-1 block w-full border p-2 rounded-md shadow-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Publish Date
            </label>
            <input
              type="date"
              name="publishDate"
              value={formatDateForInput(article.publishDate)}
              onChange={handleDateChange}
              className="mt-1 block w-full border p-2 rounded-md shadow-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Publisher
            </label>
            <input
              type="text"
              name="publisher"
              value={article.publisher}
              onChange={handleChange}
              className="mt-1 block w-full border p-2 rounded-md shadow-sm"
            />
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigateBack()}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-primaryColor hover:bg-accentColor text-white font-bold py-2 px-4 rounded"
            >
              {id ? "Update Article" : "Create Article"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ArticleForm;
