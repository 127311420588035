import { useNavigate } from "react-router-dom";
import useArticleList from "../../utils/useArticleList";

const ResearchPage = () => {
  const {
    articles,
    loading,
    searchKeyword,
    setSearchKeyword,
    handleDelete,
    handleEdit,
    currentPage,
    setCurrentPage,
    pageSize,
    totalItems,
  } = useArticleList(2);
  const navigate = useNavigate();

  const totalPages = Math.ceil(totalItems / pageSize);

  // Handle changing pages
  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl mb-4">Research</h1>
      <button
        onClick={() => navigate("create")}
        className="bg-primaryColor text-white py-2 px-4 rounded-lg hover:bg-accentColor transition-colors duration-300"
      >
        Create New Article
      </button>
      <input
        type="text"
        placeholder="Search..."
        value={searchKeyword}
        onChange={(e) => {
          setSearchKeyword(e.target.value);
          setCurrentPage(1); // Reset to first page when searching
        }}
        className="border p-2 mb-4 mt-4 w-full rounded-md shadow-sm"
      />
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table className="min-w-full bg-white rounded-lg overflow-hidden shadow-lg">
            <thead className="bg-gray-100 text-left">
              <tr>
                <th className="w-24 py-3 px-4 border-b text-xs font-semibold text-gray-600">
                  Image
                </th>
                <th className="py-3 px-4 border-b text-xs font-semibold text-gray-600">
                  Title
                </th>
                <th className="w-40 py-3 px-4 border-b text-xs font-semibold text-gray-600">
                  Category
                </th>
                <th className="w-40 py-3 px-4 border-b text-xs font-semibold text-gray-600">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {articles.map((article) => (
                <tr
                  key={article.id}
                  className="hover:bg-gray-50 transition-colors"
                >
                  <td className="py-2 px-4 border-b">
                    <img
                      src={article.image}
                      alt={article.title}
                      className="w-16 h-16 object-cover rounded"
                    />
                  </td>
                  <td
                    className="py-2 px-4 border-b text-gray-700 font-medium truncate whitespace-nowrap overflow-hidden text-ellipsis"
                    style={{ maxWidth: "200px" }}
                  >
                    {article.title}
                  </td>
                  <td
                    className="py-2 px-4 border-b text-gray-700 truncate whitespace-nowrap overflow-hidden text-ellipsis"
                    style={{ maxWidth: "150px" }}
                  >
                    {article.category}
                  </td>
                  <td className="py-2 px-4 border-b text-gray-700">
                    <button
                      onClick={() => handleEdit(article.id)}
                      className="text-blue-500 mr-4 hover:underline"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(article.id)}
                      className="text-red-500 hover:underline"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-4">
            <div>
              <p>
                Showing {(currentPage - 1) * pageSize + 1} to{" "}
                {Math.min(currentPage * pageSize, totalItems)} of {totalItems}{" "}
                entries
              </p>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => goToPage(currentPage - 1)}
                disabled={currentPage === 1}
                className={`py-2 px-4 rounded ${
                  currentPage === 1
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-primaryColor text-white hover:bg-accentColor"
                }`}
              >
                Previous
              </button>
              {[...Array(totalPages)].map((_, index) => {
                const page = index + 1;
                return (
                  <button
                    key={page}
                    onClick={() => goToPage(page)}
                    className={`py-2 px-4 rounded ${
                      currentPage === page
                        ? "bg-primaryColor text-white"
                        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                    }`}
                  >
                    {page}
                  </button>
                );
              })}
              <button
                onClick={() => goToPage(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`py-2 px-4 rounded ${
                  currentPage === totalPages || totalPages === 0
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-primaryColor text-white hover:bg-accentColor"
                }`}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ResearchPage;
