import { useState, useEffect } from "react";
import axios from "axios";
import { getToken } from "./auth";
import { baseApiUrl } from "./constants";
import IArticle from "../interfaces/article";
import { useNavigate } from "react-router-dom";

const useArticleList = (articleType?: number) => {
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [pageSize] = useState(10); // Number of items per page
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const navigate = useNavigate();

  const fetchArticles = async () => {
    setLoading(true);
    const token = getToken();
    try {
      const response = await axios.get(
        `${baseApiUrl}api/services/app/Article/GetAll`,
        {
          params: {
            Keyword: searchKeyword,
            type: articleType,
            MaxResultCount: pageSize,
            SkipCount: (currentPage - 1) * pageSize,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setArticles(response.data.result.items);
      setTotalItems(response.data.result.totalCount); // Assuming totalCount is returned
    } catch (error) {
      console.error("Error fetching articles", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, [searchKeyword, currentPage]); // Fetch articles when search keyword or current page changes

  const handleEdit = (id?: string) => {
    if (!id) {
      return;
    }
    navigate(articleType ? `edit/${id}` : `edit/${id}`);
  };

  const handleDelete = async (id?: string) => {
    if (!id) {
      return;
    }
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (userConfirmed) {
      const token = getToken();
      await axios.delete(`${baseApiUrl}api/services/app/Article/Delete`, {
        params: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // If the last item on the page is deleted, adjust currentPage if necessary
      if (articles.length === 1 && currentPage > 1) {
        setCurrentPage(currentPage - 1);
      } else {
        fetchArticles();
      }
    }
  };

  return {
    articles,
    loading,
    searchKeyword,
    setSearchKeyword,
    handleEdit,
    handleDelete,
    currentPage,
    setCurrentPage,
    pageSize,
    totalItems,
  };
};

export default useArticleList;
